<template>
<div>
<v-card max-width="370" class="mx-auto mt-5" flat>
  <Errors v-bind:errors="errors"/>

  <v-card-title>
    Event Serie
    <v-spacer></v-spacer>
    <v-icon @click="showInRoomPlan" color="primary">mdi-calendar-multiselect</v-icon>
  </v-card-title>

  <v-card-subtitle>
    {{weekdayName}}s, {{startOfSeries | formatDate }} - {{endOfSeries | formatDate }}
  </v-card-subtitle>

  <v-card-actions v-if="isAdmin">
    
    <v-container>
      
      <v-row class="mb-2">
        <v-btn 
        width="100%"
        color="primary"
        :disabled="!this.futureEvents.length"
        @click="editSeries"
        depressed>
          Serie ändern
        </v-btn>
      </v-row>
      
      <v-row class="my-2">
        <v-btn
        width="100%"
        color="error"
        depressed
        :disabled="!this.futureEvents.length"
        @click="deleteSeries">
          Serie löschen
        </v-btn>
        <v-card-subtitle class="ma-0 pa-0">
          Löscht alle zukünftigen Events aus dieser Serie (einschließlich heute).
        </v-card-subtitle>
        
      </v-row>

      <v-row class="my-2">
        <!-- Warnung: Wenn es keine Events in der Zukunft gibt, können sie auch nicht gelöscht werden -->
        <div v-if="!futureEvents.length" class="text-subtitle-2">
          Eine Serie kann nur geändert werden, wenn sie noch Events in der Zukunft hat.
        </div>
      </v-row>


      <!-- <v-row class="my-2">
        <v-btn
        width="100%"
        color="error"
        depressed
        @click="deleteWholeSeries">
          Ganze Serie löschen
        </v-btn>
      </v-row> -->
    </v-container>

  </v-card-actions>

  <div v-if="futureEvents.length">
    <v-card-title class="mb-n3">Zukünftige Events</v-card-title>
    <div v-for="event in futureEvents" v-bind:key="event.id">
      <EventInfos v-bind:event="event"/>
    </div>
  </div>
  

  <div v-if="pastEvents.length">
    <v-card-title class="mb-n3">Vergangene Events</v-card-title>
    <div v-for="event in pastEvents" v-bind:key="event.id">
      <EventInfos v-bind:event="event" />
    </div>
  </div>
  
  
  
</v-card>
</div>
</template>

<script>
import { mapState } from 'vuex';
import { db, seriesCollection } from '@/firebase';
import EventInfos from '@/components/EventInfos';
import Errors from '@/components/Errors.vue';

export default {
  name: 'InfoSeries',

  components: {
    EventInfos,
    Errors
  },

  props: {
    seriesId: { //got from route
      type: String,
      default: 'test'
    },
  },
  
  data() {
    return {
      futureEvents: [],
      pastEvents:[],
      event: {},
      startOfSeries: '',
      endOfSeries: '',
      weekdayName: '',
      errors: [],
    }
  },

  computed: {
    ...mapState(['isAdmin']),
  },

  created() {
    this.fetchEventsfromSeries();
  },

  methods: {
    async fetchEventsfromSeries() {
      this.event = {};
      this.pastEvents = [];
      this.futureEvents = [];

      /* Fetch all the other events from this series */
      try{
        const seriesDoc = await seriesCollection.doc(this.seriesId).get();

        let eventsReferences = seriesDoc.data().eventReferences;
        this.startOfSeries = seriesDoc.data().startOfSeries;
        this.endOfSeries = seriesDoc.data().endOfSeries;
        this.weekdayName = seriesDoc.data().weekday.name;

        for (let i=0; i < eventsReferences.length; i++){
          try{
            const currentEventDoc = await eventsReferences[i].get()

            var currentEvent = currentEventDoc.data();
            currentEvent.id = currentEventDoc.id

            if(currentEvent.date < new Date().toISOString().substring(0,10)){
              this.pastEvents.push(currentEvent);
            } else {
              this.futureEvents.push(currentEvent);
            }

          } catch {
            this.errors.push({
              text: 'Ein Event konnte nicht in der Datenbank gefunden werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            console.log("Could not find the event with id " + eventsReferences[i].id + " in firestore");
          }
          this.pastEvents.sort(this.compareEventsbyDate).reverse(); //sort the past Events so that the latest is at first
          this.futureEvents.sort(this.compareEventsbyDate); //sort the future Events so that the next one coming is at first
          
        }
      } catch (error) {
        this.errors.push({
          text: 'Die Serie konnte nicht aus der Datenbank geladen werden.', 
          type:'firestore',
        })
        // scroll to beginning of page to display warning
        window.scrollTo(0,0);
        throw "2Could not find this document in firestore." + error;
      }
    },

    compareEventsbyDate(event1, event2){
      if(event1.date < event2.date) {
        return -1;
      } else if(event1.date > event2.date) {
        return 1;
      } else { //if the date is the same, compare the start
        if(event1.start < event2.start) {
          return -1;
        } else if(event1.start > event2.start) {
          return 1;
        } else {
          return 0;
        }
      }
    },

    /**
     * @description deletes all the future events (starting from tomorrow) in this series   
     **/
    async deleteSeries() {
      /* Fetch all the events from this series */
      try {
        const seriesDoc = await seriesCollection.doc(this.seriesId).get();

        var eventsReferences = seriesDoc.data().eventReferences;
        let newEventsReferences = [];

        for (let i=0; i < eventsReferences.length; i++){
          //check if event is in the future
          const eventdoc = await eventsReferences[i].get();
          if (eventdoc.exists) {
            let date = eventdoc.data().date;
            let today = new Date().toISOString().substr(0,10);
            if (date >= today) {
              /* delete the event */
              eventsReferences[i].delete();
            } else {
              /* add Reference to the array newEventsReferences */
              newEventsReferences.push(db.doc(eventsReferences[i].path));
            }
          } else { 
            this.errors.push({
              text: 'Die Serie konnte nicht gelöscht werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            throw "Could not find this document in firestore";
          }

        }

        /* if all the events have been deleted (because all lay in the future), delete the whole eventsRef document */
        if (newEventsReferences.length == 0) {
          /* delete the corresponding series Reference */
          seriesCollection.doc(this.seriesId).delete();
          /* redirect to Raumplan */
          this.showInRoomPlan();
        } else {
          /* update the corresponding series Reference and set the enddate to the date of today */
          seriesCollection.doc(this.seriesId).update({
            eventReferences: newEventsReferences,
            endOfSeries: new Date().toISOString().substr(0,10),
          })
          /* reload */
          this.fetchEventsfromSeries();
        }

      } catch(error) {
        this.errors.push({
          text: 'Die Serie konnte nicht vollständig gelöscht werden.', 
          type:'firestore',
        })
        // scroll to beginning of page to display warning
        window.scrollTo(0,0);
        throw "4Could not find this document in firestore" + error;
      }
      
    },

    /**
     * @description deletes all events of that series
     * @todo weg
     */
    async deleteWholeSeries() {
      /* Fetch all the events from this series */
      try {
        const seriesDoc = await seriesCollection.doc(this.seriesId).get();
        var eventsReferences = seriesDoc.data().eventReferences;
        for (let i=0; i < eventsReferences.length; i++){
          eventsReferences[i].delete();
        }
        /* delete the corresponding series Reference */
        seriesCollection.doc(this.seriesId).delete();
        this.showInRoomPlan();
        
      } catch(error) {
        this.errors.push({
          text: 'Die Serie konnte nicht gelöscht werden.', 
          type:'firestore',
        })
        // scroll to beginning of page to display warning
        window.scrollTo(0,0);
        throw "5Could not find this document in firestore" + error;
      }
    },

    /**
     * @description redirects to editevents and giving the id of the nearest in the future laying event with. 
     * Then, this and all future events are edited in editEvent when clicking on "dauerhaft ändern"
     */
    editSeries() {
      this.$router.push('/editseries/' + this.seriesId);
    },

    showInRoomPlan() {

      if(this.futureEvents.length){
        //redirekte zum nächsten zukünftigen event
        this.$router.push({
          name: 'Raumplan', 
          params: {
            routeDate: this.futureEvents[0].date,
            routeRoom: this.futureEvents[0].roomId,
          }
        });
      } else {
        //redirekte zu raumplan heute
        this.$router.push('/raumplan');
      }
    }
  }
}
</script>